import './styles/App.css';
import {Logo, IconOpenLink, Browser, LogoMonogram} from './components/icons';

function App() {
  return (
    <div className="page">
      <header id="header">
        <Logo />
      </header>
      <div className="screen-reader-only" aria-label="Skip to section">
        <a href="#" className="skip-link">Skip to header</a>
        <a href="#content" className="skip-link">Skip to main content</a>
        <a href="#footer" className="skip-link">Skip to footer</a>
      </div>
      <div className="intro">
        <h1>Welcome to Moondust Collective</h1>
        <i>the space where imagination meets impact</i>
        <p>Under this constellation of innovative and purposeful ventures, we unite a talented team that dares to dream and experiment. Together, we are shaping a brighter and more inclusive future for everyone.</p>
        <span />
      </div>
      <div className="embed-content" id="content" role="main">
        <div className="single-embed-content">
          <div className="embed-description">
            <h3>Hello Accessible</h3>
            <a href="https://www.helloaccessible.com" target="_blank" aria-label="Link opens in a new tab" role="article">helloaccessible.com <IconOpenLink /></a>
            <p>Leading the way in inclusivity, we specialize in digital accessibility consulting to create a web that is open and welcoming to all.</p>
          </div>
          <div className="browser-container">
            <Browser />
            <embed src="https://www.helloaccessible.com" />
            <span />
          </div>
        </div>
        <div className="single-embed-content">
          <div className="embed-description">
            <h3>Hello Moonglow</h3>
            <a href="https://www.hellomoonglow.com" target="_blank" aria-label="Link opens in a new tab" role="article">hellomoonglow.com <IconOpenLink /></a>
            <p>Transforming precious moments in life into fine art, we offer photography and videography for weddings, portraits, editorials, and more—capturing stories of love, light, and emotion.</p>
          </div>
          <div className="browser-container">
            <Browser />
            <embed src="https://www.hellomoonglow.com" />
            <span />
          </div>
        </div>
      </div>
      <footer id="footer">
        <div>
          <div className="footer-left">
            <LogoMonogram />
            <h6>All rights reserved © 2024</h6>
            <h6></h6>
          </div>
          <div className="footer-center">
            <h3>Moondust</h3>
            <h4>Collective</h4>
          </div>
          <div className="footer-right">
            <h6>
              <a href="https://www.patriciagoh.com" target="_blank" aria-label="Link opens in a new tab">Made with ♡ by Patricia Goh <IconOpenLink /></a>
            </h6>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
